<template>
  <v-select
    v-model="stateValue"
    :items="states"
    :label="label"
    chips
    clearable
    deletable-chips
    dense
    hide-details
    item-text="label"
    item-value="value"
    multiple
    outlined
    prepend-inner-icon="mdi-state-machine"
    small-chips
  >
    <template v-slot:item="{item, attrs, on}">
      <v-list-item
        #default="{ active }"
        v-bind="attrs"
        v-on="on"
      >
        <v-list-item-action>
          <v-checkbox :input-value="active" />
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            <v-chip
              small
              class="text-uppercase"
              label
              :color="item.color"
            >
              {{ item.label }}
            </v-chip>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-select>
</template>
<script>
export default {
  props: {
    state: {
      type: Array,
      default: () => ([]),
    },
    label: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      states: [
        {
          label: this.$i18n.t('state.product.draft.label'),
          color: this.$i18n.t('state.product.draft.color'),
          value: 'draft',
        },
        {
          label: this.$i18n.t('state.product.pending_internal_review.label'),
          color: this.$i18n.t('state.product.pending_internal_review.color'),
          value: 'pending_internal_review',
        },
        {
          label: this.$i18n.t('state.product.rejected.label'),
          color: this.$i18n.t('state.product.rejected.color'),
          value: 'rejected',
        },
        {
          label: this.$i18n.t('state.product.pending_changes.label'),
          color: this.$i18n.t('state.product.pending_changes.color'),
          value: 'pending_changes',
        },
        {
          label: this.$i18n.t('state.product.in_internal_review.label'),
          color: this.$i18n.t('state.product.in_internal_review.color'),
          value: 'in_internal_review',
        },
        {
          label: this.$i18n.t('state.product.pending_ready.label'),
          color: this.$i18n.t('state.product.pending_ready.color'),
          value: 'pending_ready',
        },
        {
          label: this.$i18n.t('state.product.ready.label'),
          color: this.$i18n.t('state.product.ready.color'),
          value: 'ready',
        },
        {
          label: this.$i18n.t('state.product.available.label'),
          color: this.$i18n.t('state.product.available.color'),
          value: 'available',
        },
        {
          label: this.$i18n.t('state.product.unavailable_sold.label'),
          color: this.$i18n.t('state.product.unavailable_sold.color'),
          value: 'unavailable:sold',
        },
        {
          label: this.$i18n.t('state.product.unavailable_vacancy.label'),
          color: this.$i18n.t('state.product.unavailable_vacancy.color'),
          value: 'unavailable:vacancy',
        },
        {
          label: this.$i18n.t('state.product.unavailable_scheduled.label'),
          color: this.$i18n.t('state.product.unavailable_scheduled.color'),
          value: 'unavailable:scheduled',
        },
        {
          label: this.$i18n.t('state.product.unavailable_drop-ended.label'),
          color: this.$i18n.t('state.product.unavailable_drop-ended.color'),
          value: 'unavailable:drop-ended',
        },
        {
          label: this.$i18n.t('state.product.deleted.label'),
          color: this.$i18n.t('state.product.deleted.color'),
          value: 'deleted',
        },
      ],
    }
  },
  computed:{
    stateValue: {
      get () { return this.state  },
      set (value) { this.$emit('update:state', value) },
    },
  },
}
</script>
