<template>
  <v-sheet
    elevation="3"
    rounded
    class="mb-4"
  >
    <v-container
      fluid
    >
      <v-row
        dense
      >
        <v-col
          cols="12"
          md="1"
          lg="6"
        >
          <v-btn
            text
            outlined
            class="mr-2"
            @click="filterToReview"
          >
            <v-icon
              left
              color="orange darken-2"
            >
              mdi-message-question
            </v-icon>
            {{ $t('views.curation.forms.labels.filter_to_review') }}
          </v-btn>
          <v-btn
            text
            outlined
            class="mr-2"
            @click="filterRejected"
          >
            <v-icon
              left
              color="red accent-1"
            >
              mdi-message-off
            </v-icon>
            {{ $t('views.curation.forms.labels.filter_rejected') }}
          </v-btn>
          <v-btn
            text
            outlined
            @click="filterPendingReady"
          >
            <v-icon
              left
              color="green darken-3"
            >
              mdi-message-text-clock
            </v-icon>
            {{ $t('views.curation.forms.labels.filter_pending_ready') }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row
        dense
      >
        <v-col
          cols="12"
          md="1"
          lg="3"
        >
          <v-text-field
            v-model="filters.query"
            clearable
            outlined
            prepend-inner-icon="mdi-wardrobe-outline"
            hide-details
            dense
            :label="$t('views.curation.filters.query')"
          />
        </v-col>
        <v-col
          cols="12"
          md="1"
          lg="3"
        >
          <CategorySelector v-model="filters['category_id[]']" />
        </v-col>
        <v-col
          cols="12"
          md="1"
          lg="3"
        >
          <ProductStateSelector
            :state.sync="filters['state_and_reason[]']"
            :label="$t('views.curation.filters.state')"
          />
        </v-col>
        <v-col
          cols="12"
          md="1"
          lg="3"
        >
          <StoreSelector
            v-model="filters['store.id']"
            filter
          />
        </v-col>
        <v-col
          cols="12"
          md="1"
          lg="3"
        >
          <DropsSelector
            v-model="filters['drop.id']"
            filter
          />
        </v-col>
        <v-col
          cols="12"
          md="1"
          lg="3"
        >
          <v-menu
            v-model="menu_pending_at_after"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="filters['pending_at[after]']"
                :label="$t('views.curation.filters.pending_at_after')"
                prepend-inner-icon="mdi-calendar"
                hide-details
                clearable
                outlined
                dense
                readonly
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="filters['pending_at[after]']"
              @input="menu_pending_at_after = false"
            />
          </v-menu>
        </v-col>
        <v-col
          cols="12"
          md="1"
          lg="3"
        >
          <v-menu
            v-model="menu_pending_at_before"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="filters['pending_at[before]']"
                :label="$t('views.curation.filters.pending_at_before')"
                prepend-inner-icon="mdi-calendar"
                hide-details
                clearable
                outlined
                dense
                readonly
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="filters['pending_at[before]']"
              @input="menu_pending_at_before = false"
            />
          </v-menu>
        </v-col><v-col
          class="justify-end"
          cols="12"
          md="1"
          lg="2"
        >
          <v-switch
            v-model="filters['quantity[min]']"
            class="ml-2 mt-1"
            dense
            hide-details
            label="En stock"
            value="1"
          />
        </v-col>
        <v-spacer />
        <v-col
          cols="12"
          md="1"
          lg="1"
        >
          <v-btn
            icon
            @click="refresh"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
import CategorySelector from '@/components/base/Product/Input/CategorySelector.vue'
import { debounce } from '@/helpers/debounce'
import DropsSelector from '@/components/base/Product/Input/DropsSelector.vue'
import EventBus from '@/plugins/event-bus'
import { getQueryAsArray } from '@/helpers/queryString'
import ProductStateSelector from '@/components/core/ProductStateSelector.vue'
import StoreSelector from '@/components/base/StoreSelector.vue'

export default {
  name: 'CurationFilters',
  components: { DropsSelector, CategorySelector, ProductStateSelector, StoreSelector },
  data() {
    return {
      filters: {
        query: this.$route.query.query,
        'state_and_reason[]': getQueryAsArray(this.$route.query, 'state_and_reason[]'),
        'quantity[min]': this.$route.query['quantity[min]'] || null,
        'store.id': this.$route.query['store.id'],
        'drop.id': this.$route.query['drop.id'],
        'category_id[]': getQueryAsArray(this.$route.query, 'category_id[]'),
        'pending_at[after]': this.$route.query['pending_at[after]'],
        'pending_at[before]': this.$route.query['pending_at[before]'],
      },
      menu_pending_at_after: false,
      menu_pending_at_before: false,
    }
  },
  watch: {
    filters: {
      handler: debounce(function (value) {
        this.$emit('onFiltersChanged', value)
      }, 500),
      deep: true,
    },
  },
  methods: {
    refresh() {
      EventBus.$emit('CURATION_REFRESH_LIST', {})
    },
    filterRejected() {
      this.filters['state_and_reason[]'] = ['rejected', 'pending_changes']
    },
    filterToReview() {
      this.filters['state_and_reason[]'] = ['pending_internal_review']
    },
    filterPendingReady() {
      this.filters['state_and_reason[]'] = ['ready', 'pending_ready']
    },
  },
}
</script>

<style scoped>
</style>
